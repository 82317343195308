@import "./components.scss";

$nav-background: theme-color("primary") !default;

// .media-list > .list-group-item {
//   border-top-width: 0px;
//   border-bottom-width: 0px;
// }

.input-group-append > .btn-light {
  background-color: white;
  border: 1px solid #ced4da;
  border-left: 0px;
}

.navbar {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.nav-item {
  font-weight: 400;
}

.editing-navbar {
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.search-navbar {
  border-bottom: solid;
  border-bottom-color: $gray-200;
  border-bottom-width: 1px;
}

.nav-link {
  float: none;
  display: inline-block;
}

.search-navbar > ul {
  white-space: nowrap;
  overflow-x: auto;
}

.ls-uploads {
  border-radius: 0rem;
  margin-bottom: 0rem;
}

.editing-controls {
  border-top: 1px solid #ddd;
  width: 100%; 
}

.navbar-light .nav-link:hover, .navbar-light .nav-item .active {
  background-color: darken($gray-100, 5%);
}

.navbar-dark .nav-link:hover, .navbar-dark .nav-item .active {
  background-color: darken($gray-800, 3%);
}

code {
  font-size: 1rem;
}

blockquote {
  margin-bottom: $spacer;
  font-size: $blockquote-font-size;
  border-left: 5px solid $gray-200;
  padding-left: 1rem;
}

.card, .media-list {
  margin-bottom: 1.5rem;
  @include media-breakpoint-down(sm) {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    margin-bottom: 1rem;
  }
}

.list-group-item {
  @include media-breakpoint-down(sm) {
    &:first-child {
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
    }
    &:last-child {
      border-bottom-left-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
    margin-bottom: 0rem;
  }
}

.ls-section-header h3 {
  padding-bottom: 1rem;
  border-bottom: 1px solid $gray-200;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 1rem;
}

.valid-feedback {
  // display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: $form-feedback-valid-color;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba($form-feedback-valid-color,.8);
  border-radius: .2rem;
}

$ls-transition: 0.2s;

.nav-pills {
  .nav-link {
    &.active,
    .show > & {
      background-color: $nav-background
    }
  }
}
.pagination {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.card > .media-list {
  margin-bottom: 0rem;
  border-bottom: 0px;
  border-top: 0px;
}
.media-list > .media-list {
  margin-bottom: 0rem;
}
.card > .media-list > li {
  border-right: 0px;
  border-left: 0px;
  &:first-child {
    border-top: 0px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }
  &:last-child {
    border-bottom: 0px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }
}

.list-group-item + div > li:first-child {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.ls-embed {
  padding: 0.75rem !important;
}

#footer {
  position: absolute;
  margin-top: 0rem;
  margin-bottom: 0rem;
  bottom: 0;
  left: 0;
  right: 0;
}

a[role='button'] {
  cursor: pointer;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
}
.max-width {
  max-width: 1170px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
input[readonly] {
  background-color: white !important;
  cursor: text !important;
}
.panel-header {
  background-color: theme-color("primary");
  color: white;
}
.panel-header a.btn-link {
  color: white;
}
.panel-header a {
  color: white;
}
.ls-profile-header {
  background-color: white;
  color: $text-color;
}
a .icon {
  margin-right: 0.5rem;
  &:hover {
    text-decoration: none;
  }
}
a.panel-menu-item {
  color: $text-color;
}
.ls-heavy {
  font-weight: 600;
}
.ls-medium {
  font-weight: 400;
}
.ls-light {
  font-weight: 300;
}

.ls-brand {
  padding: 0px;
  height: 31px;
  width: 24px;
}

#bookmarklet-container {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 0px;
  z-index: 100;
  display: none;
  opacity: 0;
}
#bookmarklet {
  width: 1px;
  height: 1px;       
  padding: 0px;
}

.ls-controls a {
  margin-right: 1.8rem;
}

.ls-notification {
  margin-bottom: 1.5rem;
}

.ls-primary {
  padding-top: 1.5rem;
  padding-left: 0rem;
  padding-right: 0rem;
  @include media-breakpoint-down(sm) {
    padding: 0rem;
    margin-right: 0rem;
  }
}

.row {
  @include media-breakpoint-down(sm) {
    margin: 0rem;
  }
}

.col-sm-9, .col-sm-3 {
  @include media-breakpoint-down(sm) {
    padding: 0rem;
  }
}

.ls-fluid {
  padding: 0;
}

.ls-lead {
  font-size: ($font-size-base * 1.25);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ls-module-title {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: $gray-800;
  margin-bottom: 0rem;
}

.ls-module-description {
  font-weight: 300;
  font-size: 1.25rem;
  color: $gray-500;
}

.ls-module-author {
  font-weight: 300;
  font-size: 1.25rem;
  color: $gray-500;
  margin-bottom: 0.5rem;
}

div.ls-module-controls a {
  color: $text-color;
  font-size: 1rem;
  // text-decoration: none;
  // font-weight: 600;
  -o-transition: $ls-transition;
  -ms-transition: $ls-transition;
  -moz-transition: $ls-transition;
  -webkit-transition: $ls-transition;
  /* ...and now for the proper property */
  transition: $ls-transition;
}

.ls-module-controls:hover a {
  color: $link-color;
  &:hover { 
    color: $link-hover-color;
  }
}

.ls-reference:hover, .ls-reference-expanded {
  // border: 1px solid theme-color("primary");
  border-color: $gray-200;
}

.ls-reference h2, .ls-reference h3, .ls-reference h4 {
  margin-top: 0px;
  font-weight: 600;
}

.ls-reference a {
  color: $text-color;
  -o-transition:$ls-transition;
  -ms-transition:$ls-transition;
  -moz-transition:$ls-transition;
  -webkit-transition:$ls-transition;
  /* ...and now for the proper property */
  transition:$ls-transition;
}

.ls-reference:hover a, .ls-reference-expanded a {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
}

.ls-reference-title {
  font-weight: bolder;
}

.ls-reference-expansion {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.ls-reference-indicator {
  position: relative;
  float: right;
  top: -1rem;
}

.ls-reference-indicator a {
  color: white;
  &:hover {
    color: $link-color;
  }
}

/* General styles for the modal */

/* 
Styles for the html/body for special modal where we want 3d effects
Note that we need a container wrapping all content on the page for the 
perspective effects (not including the modals and the overlay).
*/
.md-perspective,
.md-perspective body {
	height: 100%;
	overflow: hidden;
}

.md-perspective body  {
	background: #222;
	-webkit-perspective: 600px;
	-moz-perspective: 600px;
	perspective: 600px;
}

// .container {
// 	background: #e74c3c;
// 	min-height: 100%;
// }

.md-modal {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 50%;
	max-width: 630px;
	min-width: 320px;
  height: auto;
  max-height: calc(100vh - 225px);
	z-index: 2000;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.md-modal-large {
  border-radius: $card-border-radius;
	position: fixed;
	top: 50%;
	left: 50%;
  width: 50%;
	max-width: 1920px;
	min-width: 320px;
  max-height: 80%;
  overflow-y: scroll;
	z-index: 2000;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
	width: 100%;
	height: 100%;
	visibility: hidden;
	top: 0;
	left: 0;
	z-index: 1999;
	opacity: 0;
	background: rgba(0,0,0,0.4);
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.md-show-overlay {
  opacity: 1;
	visibility: visible;
}

/* Effect 7:  slide and stick to top */

.md-effect-7{
	top: 0;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.md-effect-7 .md-content {
	-webkit-transform: translateY(-200%);
	-moz-transform: translateY(-200%);
	-ms-transform: translateY(-200%);
	transform: translateY(-200%);
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	transition: all .3s;
	opacity: 0;
}

.md-show.md-effect-7 .md-content {
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	-ms-transform: translateY(0%);
	transform: translateY(0%);
	border-radius: 0 0 3px 3px;
	opacity: 1;
}

// @media screen and (max-width: 32em) {
// 	body { font-size: 75%; }
// }

@media print {
  body, h1, h2, h3, ol, ul, div, span, p {
    display: block !important;
    width: auto !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
    color: black;
  }
  .media-list, .media {
    border-width: 0px;
  }
  blockquote {
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-right-width: 0px;
  }
  .col-sm-9 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  a {
    text-decoration: none;
    text-decoration-style: none;
    color: black;
    cursor: default;
  }
  @page {
    size: auto;
    margin-left: 0.5cm;
    margin-right: 0.5cm;
  }
  a[href]:after {
    content: none !important;
  }
}